
import { Vue, Component } from 'vue-property-decorator';
import SimpleButton from './SimpleButton.vue';

@Component({
  components: {
    SimpleButton,
  }
})
export default class SimpleModal extends Vue {
}
