
import { Prescription } from "@/interfaces/therapy";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class PrescriptionsList extends Vue {
  @Prop() readonly therapyId!: number;

  @Prop() prescriptions?: Prescription[];

  async mounted() {
    await this.fetchData();
  }

  @Watch("therapyId")
  async fetchData() {
    this.prescriptions = await this.$store.state.api.prescriptionsList(
      this.therapyId
    );
  }

  @Watch("prescriptions")
  get activePrescriptions(): Prescription[] {
    if (this.prescriptions === undefined) return [];
    return this.prescriptions.filter((prescription) => !prescription.archived);
  }
}
