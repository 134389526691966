
import Vue from "@/interfaces/vue";
import { JitsiMeetExternalAPI } from "@/interfaces/misc";
import LogoLoading from "@/components/LogoLoading.vue";
import { User } from "@/interfaces/user";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    LogoLoading
  },
})
export default class JitsiMeet extends Vue {
  @Prop() readonly host!: string;
  @Prop() readonly options!: any;
  @Prop() readonly password?: string;
  @Prop() readonly user?: User;

  closed = false;
  meetApi: null | JitsiMeetExternalAPI = null;
  jitsi: any;
  meetApiloaded = false;

  mounted() {
    this.initJitsiConference();
  }

  initJitsiConference() {
    try {
      this.getJitsiScript().then(() => {
        this.recreate();
      });
    } catch (error) {
      console.log(error);
    }
  }

  getJitsiScript = () =>
    // No NPM module, we have to do it in oldschool way
    new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://meet.calmsie.ai/external_api.js";
      script.async = true;
      script.onload = resolve;
      document.body.appendChild(script);
    });

  @Watch("closed")
  cleanup() {
    if (this.meetApi !== null) {
      this.meetApi = null;
    }
  }

  @Watch("options")
  recreate() {
    this.cleanup();

    // DOCS https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe
    const optionsWithNode = Object.assign({}, this.options);
    Object.assign(optionsWithNode, { parentNode: this.$refs.frame });

    // When jitsi is loaded, it add to window JitsiMeetExternalAPI class.
    const api = new (window as any).JitsiMeetExternalAPI(
      this.host,
      optionsWithNode
    );

    if (this.password !== undefined) {
      api.on("passwordRequired", () => {
        api.executeCommand("password", this.password);
      });
    }
    api.executeCommand(
      "displayName",
      `${this.user?.first_name} ${this.user?.last_name}`
    );
    api.executeCommand(
      "subject",
      this.options.subject
    )
    api.on("readyToClose", () => {
      api.dispose();
      this.closed = true;
    });
    api.on("videoConferenceJoined", () => {
      this.meetApiloaded = true;
    });

    this.meetApi = api;
  }
}
