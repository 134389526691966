
import { Vue, Component, Prop } from "vue-property-decorator";
import SimpleButton from "@/components/SimpleButton.vue";
import SimpleModal from "@/components/SimpleModal.vue";
import { Session } from "@/interfaces/therapy";
import { timeDiff } from "@/i18n-setup";
import i18n from "@/i18n";

@Component({
  components: {
    SimpleButton,
    SimpleModal,
  },
})
export default class VisitsListElem extends Vue {
  @Prop() readonly visit!: Session;

  zeropad(str: string, length: number): string {
    if (str.length < length) {
      return "0" + this.zeropad(str, length - 1);
    }
    return str;
  }

  readonly toTimeString = (dt: Date): string => {
    return (
      this.zeropad(dt.getHours().toString(), 2) +
      ":" +
      this.zeropad(dt.getMinutes().toString(), 2)
    );
  };
  details = false;

  lastUpdated: Date = new Date();
  dateUpdater = setInterval(() => {
    this.lastUpdated = new Date();
  }, 60 * 1000);

  isModalVisible = false;

  showModal() {
    this.isModalVisible = true;
  }

  closeModal() {
    this.isModalVisible = false;
  }

  cancelSession() {
    this.$store.state.api.cancelSession(this.visit.id);
    this.visit.cancelled = true;
    this.closeModal();
  }

  destroyed() {
    clearInterval(this.dateUpdater);
  }

  copyToClipboard() {
    (this.$refs.password as Vue & {
      select: () => any;
    }).select();
    document.execCommand("copy");
  }

  get isCancelled(): boolean {
    return this.visit.cancelled;
  }

  get isActive(): boolean {
    return (
      (this.isInFarFuture || this.isSoon || this.isNow) && !this.isCancelled
    );
  }

  get name(): string {
    if (this.$store.state.user.is_therapist) {
      return this.visit.user.first_name + " " + this.visit.user.last_name;
    } else {
      return (
        this.visit.therapist.user.first_name +
        " " +
        this.visit.therapist.user.last_name
      );
    }
  }

  get timeDiff(): number {
    return this.visit.date.valueOf() - this.lastUpdated.valueOf();
  }

  get isInFarFuture(): boolean {
    return this.timeDiff > 1 * 60 * 60 * 1000;
  }

  get isSoon(): boolean {
    return this.timeDiff <= 1 * 60 * 60 * 1000 && this.timeDiff >= 0;
  }

  get isNow(): boolean {
    return Math.abs(this.timeDiff) <= 1 * 60 * 60 * 1000 && this.timeDiff <= 0;
  }

  get day(): string {
    return new Intl.DateTimeFormat(this.$i18n.locale, {
      day: "2-digit",
    }).format(this.visit.date);
  }

  get month(): string {
    return Intl.DateTimeFormat(this.$i18n.locale, {
      month: "long",
    }).format(this.visit.date);
  }

  get weekday(): string {
    return Intl.DateTimeFormat(this.$i18n.locale, {
      weekday: "long",
    }).format(this.visit.date);
  }

  get remains(): string {
    return timeDiff(
      i18n,
      new Date(this.visit.date).getTime() - this.lastUpdated.getTime()
    );
  }
}
