<script type="ts">
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class LogoLoading extends Vue {}
</script>

<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 405.6 295.19"
    >
      <ellipse class="cls-1" cx="339.05" cy="288.96" rx="3.87" ry="3.73" />
      <path
        class="cls-1 path-calmsie"
        d="M385.85,410c-.13-10.33-22.91-14.78-35.22-16.39L290,385.65c-12.49-1.64-25.33-3.37-36.18-8.75s-19.37-15.36-17.89-25.76c.85-5.95,4.77-11.28,9.05-16.11,8-9,18.16-17.32,31-20.69s28.81-.56,36.18,8.82,1.6,24.71-11.69,26.69c-11.9,1.78-23.23-8-23.32-18s8.62-19,19.34-23.63,23.15-5.62,35.24-5.87c28.05-.56,56.95,2.41,83.59-4.9,9.17-2.52,18.05-6.35,24.48-12.32C455,271,452.18,247.76,438,232.93c-7.85-8.18-20.06-14.66-32.49-12.92S383.62,234.87,391,243.34c5.62,6.45,18.16,6.77,25.69,1.83s10.35-13.64,9.19-21.55-5.7-15.18-10.7-22c-15.49-21-36.93-39.88-64.32-49.47-17.57-6.15-39.89-7.62-54,3A31.79,31.79,0,0,0,285,173.8c-1.65,7.53-.49,15.88,5.38,21.8s17.17,8.33,24.76,4a15.41,15.41,0,0,0,7.27-16.06c-1-6-4.77-11.35-8.94-16.28a116.78,116.78,0,0,0-64.88-38.63c-26.4-5.45-56.2-.88-76.67,14s-29.83,40.22-20.25,61.32c7.44,16.39,27.19,29.37,48.29,28.44,6.77-.3,13.73-2.11,18.42-6.17,10.72-9.29,4.9-24.86-4.14-35.31-22.36-25.85-66-41-100.77-27.5C81.23,175.89,66.62,208.06,66,237.6c-.16,7.23.44,14.81,5,21s14,10.42,22.17,7.85c11.47-3.61,11.8-18.93,1.87-24.89s-24.7-3.34-33.67,3.6C43.39,259,47,285,63.59,299.91s42.12,20.57,66.51,22.19c12.88.86,26.05,1.21,38.33-2.13,26.07-7.1,43.32-27.54,59.67-45.8s36.26-37.25,63.4-40.59c8.3-1,17.51-.23,23.76,4.4s7.65,13.92,1.16,18.32c-4.38,3-10.6,2.94-16.26,2.84A529.63,529.63,0,0,0,246.11,261c-15,1.28-30.24,3.29-43.75,8.88-18.69,7.75-33,22.44-38.19,39.22s-1.18,35.35,10.7,49.6"
        transform="translate(-46.9 -123.87)"
      />
      <ellipse class="cls-1" cx="129.19" cy="235.47" rx="3.87" ry="3.73" />
    </svg>
</template>

<style scoped lang="scss">
@import "@/scss/variables.scss";
svg {
    height: 200px;
    width: 200px;
    margin: 10px auto;
    display: block;
    shape-rendering: auto;
    background: transparent none repeat scroll 0% 0%;
}
.cls-1 {
    fill: none;
    stroke: var(--main-blue);
    stroke-miterlimit: 10;
    stroke-width: 7px;
}
@keyframes loading {
  from {
    stroke-dashoffset: 1890;
  }
  to {
    stroke-dashoffset: -1890;
  }
}
.path-calmsie {
  stroke-dasharray: 1890;
  animation: loading 3.1s linear forwards infinite;
}
</style>
