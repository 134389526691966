
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VisitsListElem from "./VisitsListElem.vue";
import VisitsRequestElem from "./VisitsRequestElem.vue";
import { Session } from "@/interfaces/therapy";
import { get } from "@/api";

@Component({
  components: {
    VisitsListElem,
    VisitsRequestElem,
  },
})
export default class VisitsList extends Vue {
  @Prop() readonly past!: boolean;

  @Prop({
    default: false,
    required: false,
  })
  requested!: boolean;

  @Prop({
    type: Number,
    default: function() {
      return 0;
    },
  })
  visitsCount!: number;

  visits: null | Session[] = null;
  visitChanged = false;
  visitStateChangeId: number | null = null;

  @Prop({
    default: null,
    required: false,
  })
  therapyId!: number;

  async mounted() {
    await this.fetchData();
  }

  @Watch("$route")
  async fetchData() {
    const { data } = await get.sessions(this.past);
    this.visits = data;
    if (this.visits && this.$route.name === "dashboard") {
      if (this.requested) {
        this.visits = this.visits.filter(
          (x) =>
            x.therapist.id === this.$store.state.user.id &&
            !x.accepted_by_therapist &&
            !x.cancelled
        );
      } else {
        this.visits = this.visits.filter(
          (x) =>
            x.therapist.id === this.$store.state.user.id &&
            x.accepted_by_therapist &&
            !x.cancelled
        );
      }
      this.$emit("update:visitsCount", this.visits.length);
    } else {
      if (this.visits)
        this.visits = this.visits.filter(
          (x) => x.therapist.id === this.$store.state.user.id
        );
    }
  }

  @Watch("visitChanged")
  changeRequestsList() {
    if (this.visits && this.visitStateChangeId) {
      this.visits.splice(
        this.visits.map((visit) => visit.id).indexOf(this.visitStateChangeId),
        1
      );
      this.$emit("update:visitsCount", this.visits.length);
    }
  }
}
