
import Vue from "@/interfaces/vue";
import { Prop, Component } from "vue-property-decorator";
import { put, get, post } from "@/api";
import Spinner from "@/components/Spinner.vue";
import { SessionNote } from "@/interfaces/therapy";

@Component({
  components: {
    Spinner,
  },
})
export default class TherapyNotes extends Vue {
  @Prop() readonly session_id!: number;

  note = "";
  firstTimeNote = true;

  mode: "edit" | "idle" = "idle";

  submiting = false;

  successMessage = "";
  errorMessage = "";

  mounted() {
    this.getSessionNote();
  }

  async getSessionNote() {
    try {
      const { data } = await get.sessionNote(this.session_id);
      if (data) {
        this.note = data.content;
        this.firstTimeNote = false;
      }
    } catch (error) {
      console.log(error)
    }
  }

  async submitNote() {
    this.submiting = true;
    if (this.firstTimeNote) {
      try {
        await this.createNote();
        this.showSuccess();
      } catch (error) {
        this.showError();
      }
    } else {
      try {
        await this.updateNote();
        this.showSuccess();
      } catch (error) {
        this.showError();
      }
    }
    this.submiting = false;
  }

  async updateNote() {
    const sessionNote: SessionNote = Object.assign({
      session: this.session_id,
      content: this.note,
    });
    return await put.sessionNote(this.session_id, sessionNote);
  }

  async createNote() {
    const sessionNote: SessionNote = Object.assign({
      session: this.session_id,
      content: this.note,
    });
    this.firstTimeNote = false
    return await post.sessionNotes(sessionNote);
  }

  showError() {
    this.errorMessage = this.$t("visit.error") as string;
    setTimeout(() => {
      this.errorMessage = "";
    }, 3000);
    this.mode = "idle";
  }

  showSuccess() {
    this.successMessage = this.$t("visit.success") as string;
    setTimeout(() => {
      this.successMessage = "";
    }, 3000);
    this.mode = "idle";
  }
}
