
import Vue from "@/interfaces/vue";
import { Therapy } from "@/interfaces/therapy";
import { Messages } from "@/interfaces/therapy";
import { Component, Prop, Watch } from "vue-property-decorator";
import { get, post } from "@/api";
import { Route } from "vue-router";
import Spinner from "@/components/Spinner.vue"

Component.registerHooks(["beforeRouteLeave"]);
@Component({
  components: {
    Spinner    
  }
})
export default class MeetingMessenger extends Vue {
  @Prop()
  therapyId!: number;
  @Prop({
    default: true,
    required: false,
  })
  showHead!: boolean;
  therapy?: Therapy;
  userId?: number;
  therapistPhoto?: string;
  loaded = false;
  error: any = null;
  timer: ReturnType<typeof setTimeout> | undefined = undefined;
  messages: Messages | null = null;
  newMessage = "";
  lastMessageId: number | null = null;
  mounted() {
    this.fetchData();
    this.$nextTick(this.scrollMessages);
    this.timer = setInterval(this.fetchMessages, 4000);
  }
  scrollMessages() {
    const container = this.$el.querySelector("#messages");
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }
  async fetchMessages() {
    try {
      const { data } = await get.messages(this.therapyId, "");
      this.messages = data;
      if (
        this.messages &&
        this.messages.results.length > 0 &&
        this.lastMessageId != this.messages.results[0].id
      ) {
        this.lastMessageId = this.messages.results[0].id;
        this.$nextTick(this.scrollMessages);
      }
    } catch (err) {
      this.error = err;
    }
  }
  async fetchData() {
    try {
      const { data } = await get.therapy(this.therapyId);
      this.therapy = data;
      if (this.therapy) {
        const { data } = await get.therapist(this.therapy.therapist);
        this.therapistPhoto = data.photo;
      }
    } catch (err) {
      this.error = err;
    }
    this.fetchMessages();
    this.loaded = true;
  }
  async sendMessage() {
    if (!this.loaded) {
      return;
    }
    try {
      const { data: message } = await post.sendMessage(
        this.therapyId,
        this.newMessage
      );
      if (this.messages) {
        this.messages.results.unshift(message);
        this.lastMessageId = message.id;
        this.$nextTick(this.scrollMessages);
      }
      this.newMessage = "";
    } catch (err) {
      this.error = err;
    }
  }
  beforeRouteLeave(to: Route, from: Route, next: any) {
    if (this.timer) clearInterval(this.timer);
    next();
  }
  @Watch("$route")
  refetch() {
    this.messages = null;
    this.fetchData();
  }
}
