
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import JitsiMeet from "@/components/JitsiMeet.vue";
import Drawer from "@/components/Drawer.vue";
import PrescriptionsList from "@/components/PrescriptionsList.vue";
import SimpleButton from "@/components/SimpleButton.vue";
import VisitsList from "./visits/VisitsList.vue";
import { Session } from "@/interfaces/therapy";
import { get } from "@/api";
import TherapyNotes from "@/components/notes/TherapyNotes.vue";
import MeetingMessenger from "@/components/meeting/MeetingMessenger.vue";

@Component({
  components: {
    JitsiMeet,
    Drawer,
    PrescriptionsList,
    SimpleButton,
    MeetingMessenger,
    VisitsList,
    TherapyNotes
  },
})
export default class Visit extends Vue {
  session!: Session;
  selected = 1;
  loaded = false
  isTherapist = this.$store.state.user?.is_therapist

  mounted() {
    this.fetchData();
  }
  async fetchData() {
    const { data } = await get.sessionDetails(
      Number(this.$route.params.sessionId)
    );
    this.session = data;
    this.loaded = true
  }

  get meetOptions() {
    return {
      roomName: `${this.session.room_password}`,
      subject: `Therapy session ${this.$route.params.sessionId}`,
      height: 560
    };
  }
}
